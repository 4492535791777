import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";

import {
    Box,
    Button, Chip,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Radio,

} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {
    useGetHistoryQuery
} from "../../core/reducers/campaign";
import Loading from "../Loading";
import {Alert, LoadingButton} from "@mui/lab";
import ReactJsonViewCompare from 'react-json-view-compare';
import ReactJson from "react-json-view";
import LogItem from "../AdminPanel/LogItem";

const HistoryDialog = ({item, open, setOpen, env}) => {

    const {data, isLoading, isError, error} = useGetHistoryQuery({environment: env, event_id: item.id}, {skip: !open});


    const [openDiff, setOpenDiff] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleCloseDiff = () => {
        setOpenDiff(false);
        setOpenInfo(false);
        setSelectedRow(null);
    };

    const groupByTransferId = (data) => {
        const logs = JSON.parse(JSON.stringify(data.logs));
        const transferIdMap = new Map();

        const result = logs.filter(log => {
            const transferId = log.info.transferId;

            if (!transferId) {
                return true;
            }

            if (!transferIdMap.has(transferId)) {
                transferIdMap.set(transferId, log);
            }

            const existingLog = transferIdMap.get(transferId);
            if (!existingLog.related) {
                existingLog.related = [];
                existingLog.related.push(JSON.parse(JSON.stringify(existingLog)));
                existingLog.adminAction = 'EVENT_TRANSFER'
                return true
            }
            if(
                !existingLog.info.hasOwnProperty('source') &&
                log.info.hasOwnProperty('source')
            ) {
                existingLog.info['source'] = log.info.source
                existingLog.info['target'] = log.info.target
            }
            existingLog.related.push(log);
            return false;
        });

        debugger
        return { logs: result };
    }


    const onSelectedRow = useCallback((row) => {
        setSelectedRow(row);
    }, []);

    const onOpenDiff = useCallback((value) => {
        setOpenDiff(value);
    }, []);

    const onOpenInfo = useCallback((value) => {
        setOpenInfo(value);
    }, []);



    const transformedData = data==undefined?undefined:groupByTransferId(data)

    console.log('transformedData')
    console.log(transformedData)

    return (
        <><Dialog
            maxWidth={'800px'}
            width={'800px'}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "1100px",
                        top: "40px"
                    },
                },
            }}
            PaperProps={{
                sx: {
                    margin: 0,
                    top: 0,
                    transform: "none", // Prevents MUI from overriding the positioning
                    position: "absolute", // Ensures the dialog stays at the top
                },
            }}
            onClose={() => setOpen(false)} open={open}>
            <DialogTitle>History of <b>{item.id}</b> in <b>{env}</b></DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpen(false)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
            <DialogContent>

                <Loading isLoading={isLoading} isError={isError} error={error}>
                    {transformedData != undefined ?
                        <>
                            {transformedData.logs.map(item => (
                                <LogItem key={item.id} env={env} item={item} setSelectedRow={onSelectedRow} setOpenDiff={onOpenDiff} setOpenInfo={onOpenInfo} />
                            ))}
                        </>
                        : <Alert severity={'info'}>no data for {item.id}</Alert>
                    }
                </Loading>
            </DialogContent>
            <DialogActions>

                <Button variant="contained"
                        onClick={()=>{setOpen(false)}}>
                    Close
                </Button>

            </DialogActions>
        </Dialog>

            {openDiff&&<Dialog open={openDiff} onClose={handleCloseDiff} maxWidth="md" fullWidth>
                <DialogTitle>JSON Diff</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDiff}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent>
                    {!openInfo&&<ReactJsonViewCompare newData={selectedRow.newData} oldData={selectedRow.oldData} />}
                    {openInfo&&<ReactJson
                        theme={'bright:inverted'}
                        src={selectedRow.info}
                        iconStyle={'square'}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={false}
                    />}
                </DialogContent>
                <DialogActions>


                    <Button variant="outlined"
                            onClick={handleCloseDiff}>
                        Close
                    </Button>

                </DialogActions>
            </Dialog>}
        </>
    );
}
export default React.memo(HistoryDialog);
