import dayjs from "dayjs";

export function parseFormDataTextToJsonString(text) {
    if (!text.includes("form-data")) {
        return JSON.stringify(text);
    } else {
        let formDataStr = text.split("form-data; name=");
        let objectArray = [];
        formDataStr.forEach(subString => {
            const data = subString.substring(subString.indexOf("\\"), subString.indexOf('--'))
                .replaceAll("\r\n\r\n", ":")
                .replaceAll("\r\n", "");
            objectArray.push(data)
        })
        return objectArray.join(' ');
    }
}

export const formatdate = (timestamp) => {
    let d = timestamp ? dayjs(timestamp * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss') : null
    return d;
}

export function getDomainAlias() {
    let h = window.location.hostname
    if(!domainAlias.hasOwnProperty(h)) {
        return 'local';
    }
    return domainAlias[h]
}

export const domainAlias = {
    'localhost': 'local',
    'mephisto-cross.stage.belka-games.com': 'staging',
    'ba-mephisto.belka-games.com': 'ba',
    'mwe-mephisto.belka-games.com': 'rt',
    'toh-mephisto.belka-games.com': 'toh',
    'cm-mephisto.belka-games.com': 'cm',
    'hpr-mephisto.belka-games.com': 'hpr',
    'cb-mephisto.belka-games.com': 'cb',
    'wj-mephisto.belka-games.com': 'wj',
    'bs-mephisto.belka-games.com': 'bs',
}

export const configs = {
    "staging": {
        baseUrl: "https://cross-campaign.stage.belka-games.com/campaign",
        prodUrl: "https://cross-campaign-prod.stage.belka-games.com/campaign",
        abTestUrl: "https://cross-abtest.stage.belka-games.com/abtest",
        abTestProdUrl: "https://cross-abtest-prod.stage.belka-games.com/abtest",
        title: 'staging',
        msal: {
            auth: {
                authority: 'https://login.microsoftonline.com/3ac24b86-0088-49b2-98d0-0acf6e8b1a6f/',
                clientId: 'fcd80d85-7389-41f5-8a95-50f2b5281574',
                redirectUri: document.location.host=='localhost:3000'?'http://localhost:3000/':'https://mephisto-cross.stage.belka-games.com/'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            }
        }
    },
    "ba": {
        baseUrl: "https://ba-campaign.stage.belka-games.com/campaign",
        prodUrl: "https://ba-campaign.belka-games.com/campaign",
        abTestUrl: "https://ba-abtest.stage.belka-games.com/abtest",
        abTestProdUrl: "https://ba-abtest.belka-games.com/abtest",
        title: 'BA',
        msal: {
            auth: {
                authority: 'https://login.microsoftonline.com/3ac24b86-0088-49b2-98d0-0acf6e8b1a6f/',
                clientId: 'ba2984f8-a319-4591-97f1-866cb3ecaa3b',
                redirectUri: document.location.host=='localhost:3000'?'http://localhost:3000/':'https://ba-mephisto.belka-games.com/'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            }
        }
    },
    "rt": {
        baseUrl: "https://mwe-campaign.stage.belka-games.com/campaign",
        prodUrl: "https://mwe-campaign.belka-games.com/campaign",
        abTestUrl: "https://mwe-abtest.stage.belka-games.com/abtest",
        abTestProdUrl: "https://mwe-abtest.belka-games.com/abtest",
        title: 'RT',
        msal: {
            auth: {
                authority: 'https://login.microsoftonline.com/3ac24b86-0088-49b2-98d0-0acf6e8b1a6f/',
                clientId: '9beaab1b-8e8c-4025-b449-922d5e0a804e',
                redirectUri: document.location.host=='localhost:3000'?'http://localhost:3000/':'https://mwe-mephisto.belka-games.com/'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            }
        }
    },
    "toh": {
        baseUrl: "https://toh-campaign.stage.belka-games.com/campaign",
        prodUrl: "https://toh-campaign.belka-games.com/campaign",
        abTestUrl: "https://toh-abtest.stage.belka-games.com/abtest",
        abTestProdUrl: "https://toh-abtest.belka-games.com/abtest",
        title: 'ToH',
        msal: {
            auth: {
                authority: 'https://login.microsoftonline.com/3ac24b86-0088-49b2-98d0-0acf6e8b1a6f/',
                clientId: '6e861225-5e66-43f3-92ba-1a6c5ee4639b',
                redirectUri: document.location.host=='localhost:3000'?'http://localhost:3000/':'https://toh-mephisto.belka-games.com/'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            },
            system: {
                tokenRenewalOffsetSeconds: 300 // обновление токена за 5 минут до его истечения
            }
        }
    },
    "cm": {
        baseUrl: "https://cm-campaign.stage.belka-games.com/campaign",
        prodUrl: "https://cm-campaign.belka-games.com/campaign",
        abTestUrl: "https://cm-abtest.stage.belka-games.com/abtest",
        abTestProdUrl: "https://cm-abtest.belka-games.com/abtest",
        title: 'CM',
        msal: {
            auth: {
                authority: 'https://login.microsoftonline.com/3ac24b86-0088-49b2-98d0-0acf6e8b1a6f/',
                clientId: 'f4724cca-dd36-4995-8dcd-b841cb3e1b35',
                redirectUri: document.location.host=='localhost:3000'?'http://localhost:3000/':'https://cm-mephisto.belka-games.com/'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            }
        }
    },
    "wj": {
        baseUrl: "https://wj-campaign.stage.belka-games.com/campaign",
        prodUrl: "https://wj-campaign.belka-games.com/campaign",
        abTestUrl: "https://wj-abtest.stage.belka-games.com/abtest",
        abTestProdUrl: "https://wj-abtest.belka-games.com/abtest",
        title: 'WJ',
        msal: {
            auth: {
                authority: 'https://login.microsoftonline.com/3ac24b86-0088-49b2-98d0-0acf6e8b1a6f/',
                clientId: '8395213c-1e2d-49d1-bc2c-9061be80e2b0',
                redirectUri: document.location.host=='localhost:3000'?'http://localhost:3000/':'https://wj-mephisto.belka-games.com/'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            }
        }
    },
    "hpr": {
        baseUrl: "https://hpr-campaign.stage.belka-games.com/campaign",
        prodUrl: "https://hpr-campaign.belka-games.com/campaign",
        abTestUrl: "https://hpr-abtest.stage.belka-games.com/abtest",
        abTestProdUrl: "https://hpr-abtest.belka-games.com/abtest",
        title: 'HPR',
        msal: {
            auth: {
                authority: 'https://login.microsoftonline.com/3ac24b86-0088-49b2-98d0-0acf6e8b1a6f/',
                clientId: '68c091ea-29ea-464a-b13d-a7e7732f8f8b',
                redirectUri: document.location.host=='localhost:3000'?'http://localhost:3000/':'https://hpr-mephisto.belka-games.com/'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            }
        }
    },
    "cb": {
        baseUrl: "https://cb-campaign.stage.belka-games.com/campaign",
        prodUrl: "https://cb-campaign.belka-games.com/campaign",
        abTestUrl: "https://cb-abtest.stage.belka-games.com/abtest",
        abTestProdUrl: "https://cb-abtest.belka-games.com/abtest",
        title: 'CB',
        msal: {
            auth: {
                authority: 'https://login.microsoftonline.com/3ac24b86-0088-49b2-98d0-0acf6e8b1a6f/',
                clientId: '6a101c4a-255a-43dc-8e08-b984103c18fc',
                redirectUri: document.location.host=='localhost:3000'?'http://localhost:3000/':'https://cb-mephisto.belka-games.com/'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            }
        }
    },
    "bs": {
        baseUrl: "https://bs-campaign.stage.belka-games.com/campaign",
        prodUrl: "https://bs-campaign.belka-games.com/campaign",
        abTestUrl: "https://bs-abtest.stage.belka-games.com/abtest",
        abTestProdUrl: "https://bs-abtest.belka-games.com/abtest",
        title: 'BS',
        msal: {
            auth: {
                authority: 'https://login.microsoftonline.com/3ac24b86-0088-49b2-98d0-0acf6e8b1a6f/',
                clientId: '4afc5395-33b7-4155-92c1-ab80aede98a6',
                redirectUri: document.location.host=='localhost:3000'?'http://localhost:3000/':'https://bs-mephisto.belka-games.com/'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            }
        }
    },
    "local": {
        baseUrl: "https://cross-campaign.stage.belka-games.com/campaign",
        prodUrl: "https://cross-campaign-prod.stage.belka-games.com/campaign",
        abTestUrl: "https://cross-abtest.stage.belka-games.com/abtest",
        abTestProdUrl: "https://cross-abtest-prod.stage.belka-games.com/abtest",
        title: 'staging',
        msal: {
            auth: {
                authority: 'https://login.microsoftonline.com/3ac24b86-0088-49b2-98d0-0acf6e8b1a6f/',
                clientId: 'fcd80d85-7389-41f5-8a95-50f2b5281574',
                redirectUri: document.location.host=='localhost:3000'?'http://localhost:3000/':'https://mephisto-cross.stage.belka-games.com/'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            }
        }
    },
}
