import {Autocomplete, CircularProgress, Divider, FormHelperText, Grid, TextField, Tooltip} from "@mui/material";
import {Fragment, useEffect, useRef, useState} from "react";
import {useGetAllBundlesQuery, useGetTagsQuery} from "../../../core/reducers/campaign";
import {getEnvName} from "../../Utils/CommonUtils";
import {useGetAllCampaignTypesQuery} from "../../../core/reducers/campaignType";
import {MODE} from "../campaignMode";
import {useLocation, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import BasicDateTimePicker from "../../Utils/BasicDateTimePicker";
import dayjs from "dayjs";
import {Box} from "@mui/system";

export default function BasicStep(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const sourceMode = useSelector((state) => state.data.SourceMode);

    const {
        data: bundles,
        isFetching,
        isLoading
    } = useGetAllBundlesQuery(getEnvName('env', sourceMode, searchParams))

    const {
        data: tagsData,
        isLoading: isLoadingTags
    } = useGetTagsQuery(getEnvName('env', sourceMode, searchParams))

    const {
        data: types,
        status: typeStatus
    } = useGetAllCampaignTypesQuery({},{
        pollingInterval: 10000,
    })


    const SHORT_CAMPAIGN_NAMES = types!=undefined ? Object.keys(types).reduce((acc, item) => {
            const abbreviation = types[item].metaData?.abbreviation;
            if (abbreviation) {
                acc[item] = abbreviation;
            }
            return acc;
        }, {})
        : {};


    const interactionType = props.types&&props.types.hasOwnProperty(props.currentCampaign.offerType) ? props.types[props.currentCampaign.offerType].interactionType : '';
    const matchmakingTypes = props.types&&props.types.hasOwnProperty(props.currentCampaign.offerType)&&props.types[props.currentCampaign.offerType].matchmakingTypes!=null ? props.types[props.currentCampaign.offerType].matchmakingTypes : [];

    const [filteredBundles, setFilteredBundles] = useState([]);
    useEffect(() => {
        if (bundles && props.currentCampaign.offerType) {
            const newFilteredBundles = bundles
                .filter(bundle => bundle.campaignType === props.currentCampaign.offerType)
                .map(bundle => bundle.id)
                .sort();
            setFilteredBundles(newFilteredBundles);
        }
    }, [bundles, props.currentCampaign.offerType]); // Следим за изменениями в bundles и offerType

    console.log('filteredBundles')
    console.log(bundles)
    console.log(filteredBundles)


    const tags = tagsData == undefined ? [] : tagsData.tags

    const existingTags = props.currentCampaign.tags ? props.currentCampaign.tags.map(tag => tag.trim()) : [];
    const [selectedTags, setSelectedTags] = useState(existingTags);

    // Handle tag selection/deselection
    const handleTagChange = (event, newTags) => {
        // Remove any empty tags and trim

        const cleanedTags = newTags.map(tag => tag.trim()).filter(tag => tag !== '');

        setSelectedTags(cleanedTags);

        props.handleChange({
            target: {
                name: 'tags',
                value: cleanedTags
            }
        });
    };

    const [inputValue, setInputValue] = useState('');


    const inputRef = useRef(null);


    const handleBlur = () => {
        handleTagAddition();
    };

    const handleTagAddition = () => {
        const newTag = inputValue.trim();

        if (newTag) {
            const updatedTags = [...selectedTags, newTag];
            setSelectedTags(updatedTags);

            const tagsString = updatedTags.join(', ');
            const cleanedTags = updatedTags.map(tag => tag.trim()).filter(tag => tag !== '');

            props.handleChange({
                target: {
                    name: 'tags',
                    value: cleanedTags
                }
            });

            if (inputRef.current) {
                inputRef.current.value = '';
            }
            setInputValue('');
        }
    };

    const handleInputChange = (event) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            handleTagAddition()
        }
    };

    return (
        <Fragment>
            <Grid container spacing={3}>
                {props.types &&
                    <Grid item xs={12}>
                        <Autocomplete
                            disabled={searchParams.get('mode')=='variants' || props.mode === MODE.UPDATE}
                            required
                            options={Object.keys(props.types).sort()}

                            getOptionLabel={(option) => (
                                option + (SHORT_CAMPAIGN_NAMES.hasOwnProperty(option) ? ' (' + SHORT_CAMPAIGN_NAMES[option] + ')' : '')
                            )}

                            value={props.currentCampaign.offerType ? props.currentCampaign.offerType : null}


                            onChange={(event, value) =>
                                props.handleOfferTypeChange(value, props.types)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    id="offerType"
                                    name="offerType"
                                    variant="outlined"
                                    label="Type"
                                    error={props.errorFields.has('offerType')}
                                />
                            )}
                        />
                    </Grid>
                }

                {bundles && <Grid item xs={12}>
                    <Autocomplete
                        disabled={searchParams.get('mode')=='variants'}
                        required
                        options={filteredBundles}
                        onChange={(event, value) =>
                            props.handleBundleChange(value)}
                        value={props.currentCampaign.bundleId}
                        renderInput={params => (
                            <TextField
                                {...params}
                                id="bundleId"
                                name="bundleId"
                                label="Bundle ID *"
                                variant="outlined"
                                slotProps={{
                                    input: {
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {(isLoading||isFetching) ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    },
                                }}
                            />
                        )}
                    />
                </Grid>}

                <Grid item xs={12}>
                    <Divider component="hr"  />
                </Grid>

                {interactionType == 'TOURNAMENT'&&matchmakingTypes.length>0 && <Grid item xs={12}>
                    <Autocomplete
                        required
                        options={matchmakingTypes}
                        value={props.currentCampaign.matchmakingType ? props.currentCampaign.matchmakingType : null}
                        onChange={(event, value) =>
                            props.handleMatchmakingTypeChange(event, value)}

                        renderInput={params => (
                            <TextField
                                {...params}
                                id="offerType"
                                name="offerType"
                                variant="outlined"
                                label="Matchmaking Type *"
                                error={props.errorFields.has('matchmakingType')}
                            />
                        )}
                    />
                </Grid>}

                <Grid item xs={12}>
                    <TextField
                        required
                        disabled={props.mode === MODE.UPDATE }
                        id="campaignId"
                        name="campaignId"
                        label="Campaign ID"
                        error={props.errorFields.has('campaignId') || props.errorFields.has('regexp') || props.currentCampaign.campaignId?.includes('(Clone)')}
                        fullWidth
                        value={props.currentCampaign.campaignId}
                        variant="outlined"
                        onChange={props.handleChange}
                        onBlur={props.handleInput}
                    />
                    {props.errorFields.has('regexp') && <FormHelperText style={{color: "red"}}>
                        Campaign ID must match <a href={"https://belka-games.atlassian.net/wiki/spaces/BAC/pages/4008345627/Mephisto+Naming+Rules"} target={"_blank"}>Mephisto Naming Rules</a>
                    </FormHelperText>}
                    {props.errorFields.has('campaignId') && <FormHelperText style={{color: "red"}}>
                        This id is already taken
                    </FormHelperText>}{props.currentCampaign.campaignId?.includes('(Clone)') && <FormHelperText style={{color: "red"}}>
                        Change Campaign ID, it shouldn't contain "Clone"
                    </FormHelperText>}
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        id="groupId"
                        name="groupId"
                        label="Group ID"
                        value={props.currentCampaign.groupId}
                        fullWidth
                        variant="outlined"
                        onChange={props.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="priority"
                        name="priority"
                        label="Priority"
                        error={props.errorFields.has('priority')}
                        value={props.currentCampaign.priority}
                        fullWidth
                        variant="outlined"
                        onChange={props.handleChange}
                    />
                </Grid>


                <Grid item xs={12}>


                    <Autocomplete
                        multiple
                        freeSolo
                        id="tags-autocomplete"
                        options={isLoadingTags ? [] : (tags || [])}
                        value={selectedTags}
                        onChange={handleTagChange}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => setInputValue(newInputValue)} // Обновляем inputValue

                        loading={isLoadingTags}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tags (press enter or , to split tags)"
                                variant="outlined"
                                inputRef={inputRef}

                                error={props.errorFields.has('tags')}
                                onKeyDown={handleInputChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {isLoadingTags ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
}