import dayjs from "dayjs";
import {Button, Checkbox, FormControlLabel, FormGroup, Link, Table, TableCell, TableRow} from "@mui/material";
import {memo, useMemo, useState} from "react";
import Audience from "../Publisher/Audience";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import LogBundleDetails from "./LogBundleDetails";

const LogItem = memo(function({item, setSelectedRow, setOpenDiff, setOpenInfo}) {

    const [showDetails, setShowDetails] = useState(false)

    const STATUS_NAME = {
        "STATUS_CHANGING": "changed status to",
        "EVENT_CREATION": "created",
        "EVENT_UPDATE": "edited campaign",
        "EVENT_CREATION_BY_TRANSFER": "created by transfer",
        "EVENT_UPDATE_BY_TRANSFER": "updated by transfer",
        "EVENT_CREATION_BY_TEMPLATE": "created campaign from template",
        "BUNDLE_UPDATE": "changed bundles",
        "BUNDLE_CREATION": "bundle created",
        "BUNDLE_DELETE": "bundle deleted",
        "BUNDLE_ROLLBACK": "rolled back",
        "EVENT_TRANSFER": "transfered",
    }


    const getBundleDiff = (json1, json2) => {
        let changes = {}
        if(json1 == undefined || json2 == undefined) return []
        if(!json1.hasOwnProperty('generations')) return []
        if(!json2.hasOwnProperty('generations')) return []

        // Проверяем изменения в существующих ключах
        if(Object.keys(json1).length > 0)
            for (const [key, value1] of Object.entries(json1.generations)) {
                if (json2.generations.hasOwnProperty(key)) {
                    const value2 = json2.generations[key];
                    if (value1.bundleVersion !== value2.bundleVersion) {
                        changes[key] = {
                            generation: key,
                            changeType: 'modified',
                            bundleVersion: value2.bundleVersion

                        };
                    }
                }
            }

        // Проверяем добавленные ключи во втором объекте
        if(Object.keys(json2).length > 0)
            for (const key in json2.generations) {
                if (!json1.generations.hasOwnProperty(key)) {
                    changes[key] = {
                        generation: key,
                        changeType: 'added',
                        bundleVersion: json2.generations[key].bundleVersion
                    };
                }
            }

        return changes
    }

    const findChanges = (json1, json2) => {
        let changes = [];
        if(json1 == undefined || json2 == undefined) return false

        if(!json2.hasOwnProperty('generations')&&!json1.hasOwnProperty('generations')) return []

        changes = getBundleDiff(json1, json2)

        let last = json2?.generations[Object.keys(json2.generations).pop()]
        return Object.keys(changes).length>0 ? changes : {[last.generation]:{
            generation: last.generation,
            changeType: 'added',
            bundleVersion: last.bundleVersion
        }};
    }


    const getTitle = (item) => {
        return <span className={item.adminAction.toLowerCase()}>{STATUS_NAME.hasOwnProperty(item.adminAction) ? STATUS_NAME[item.adminAction] : item.adminAction}</span>
    }

    const formatGenerationTags = (generations) => {
        return Object.keys(generations).map((key) => (
            <><span key={key} className={"tag"}>
                        g{key}/v{generations[key].bundleVersion}
                    </span>&nbsp;</>
        ))
    }

    const getBundleTags = (data) => {
        var generations = null
        if(data.newData.hasOwnProperty('fixedBundle')&&data.newData?.fixedBundle!=null&&data.newData?.fixedBundle!=undefined) {
            generations = data.newData?.fixedBundle?.generations
        }
        if(data.newData.hasOwnProperty('generations')&&data.newData?.generations!=null&&data.newData?.generations!=undefined) {
            generations = data.newData?.generations
        }
        if(item.hasOwnProperty('info')&&item?.info?.currentBundle!=undefined&&item?.info?.currentBundle!=null) {
            generations = data.info?.currentBundle?.generations
        }
        if(generations == undefined || generations == null) return <>n/a</>

        return formatGenerationTags(generations)
    }

    const getBundlesInfo = (item) => {
        return <></>
    }

    const getInfo = (item) => {
        //return JSON.stringify(item)
        let diff = null

        switch(item.adminAction) {
            case 'BUNDLE_DELETE':
                diff = findChanges(item.newData, item.oldData)
                return <>
                    {getTitle(item)} {formatGenerationTags(diff)} in <b className={item.info.environment}>{item.info.environment}</b> environment
                </>
            case 'BUNDLE_ROLLBACK':
                diff = findChanges(item.oldData, item.newData)
                return <>{getTitle(item)} bundle to {formatGenerationTags(diff)} in <b className={item.info.environment}>{item.info.environment}</b> environment</>
            case 'EVENT_CREATION':
                if(item.hasOwnProperty('info')&&item.info?.hasOwnProperty('original')) {
                    return <>cloned campaign with bundles {getBundleTags(item)} from <Link target={"_blank"} href={'/creator/campaigns/'+item.info.original+'?env=development'}><b>{item.info.original}</b> <OpenInNewIcon fontSize={"8px"}/></Link></>
                }
                return <>created campaign with bundles {getBundleTags(item)}</>
            case 'EVENT_TRANSFER':
                return <>transferred campaign from <b className={item.info?.source}>{item.info?.source}</b> to <b className={item.info?.target}>{item.info?.target}</b></>;
            case 'EVENT_UPDATE_BY_TRANSFER':
                if(item.eventType=='CAMPAIGN')
                    return <>edited campaign by transfer</>;
                else
                    return <>changed bundles to {getBundleTags(item)} by transfer</>;

            case 'EVENT_CREATION_BY_TRANSFER':
                if(item.eventType=='CAMPAIGN')
                    return <>created campaign with bundles {getBundleTags(item)} by transfer</>;
                else
                    return <>created bundles {getBundleTags(item)} by transfer</>;
            //case 'EVENT_UPDATE_BY_TRANSFER':
            case 'BUNDLE_UPDATE':
                diff = findChanges(item.oldData, item.newData)


                if(item.info.hasOwnProperty('buildLink')) {
                    return <div>uploaded new bundle {formatGenerationTags(diff)}</div>

                }

                return <div>bundle update to {getBundleTags(item)} by another transfer</div>
            case 'STATUS_CHANGING':
                return <div>changed status to <span
                    className={"status status-" + item.newData.status.toLowerCase()}>{item.newData.status}</span></div>
            case 'EVENT_CREATION_BY_TEMPLATE':
                var tags = getBundleTags(item)

                if(tags == 'n/a') {
                    diff = findChanges(item.oldData, item.newData)
                    if(diff.length == 0 || !diff[0].hasOwnProperty('version')) {
                        console.log(item.id)
                    } else tags = diff.map((item, index) => (
                        <span key={index} className={"tag"}>
                        g{item.generation}/v{item.version?.created}
                    </span>
                    )).reduce((prev, curr) => [prev, ', ', curr]);
                }

                return <>
                    created campaign with bundles {tags} from template <Link target={"_blank"} href={"/creator/templates/"+item.info.campaignTemplateId + "?env=development"} variant="body2">
                        <b>{item.info.campaignTemplateId}</b><OpenInNewIcon fontSize={"8px"}/>
                    </Link>
                </>
            default:
                return getTitle(item)
        }

    }

    const getChangedRows = (newData, oldData) => {
        const changes = [];
        for (const key in newData) {
            if (JSON.stringify(newData[key]) !== JSON.stringify(oldData[key])) {
                changes.push(key);
            }
        }
        return changes;
    };

    const getTableRows = (item) => {
        const { newData, oldData, adminAction } = item;
        const allRows = [
            { label: 'Type', key: 'type', value: newData.type },
            { label: 'Bundle ID', key: 'bundleId', value: newData.bundleId },
            { label: 'Group ID', key: 'groupId', value: newData.groupId },
            { label: 'Priority', key: 'priority', value: newData.priority },
            { label: 'Tags', key: 'tags', value: newData.tags!=undefined?newData.tags.join(', '):'' },
            //{ label: 'Tag', key: 'tag', value: newData.tag != undefined && newData.tag != null ? newData.tag : ''},
            {
                label: 'Status',
                key: 'status',
                value: (
                    <>
                        <span className={`status status-${newData?.status?.toLocaleLowerCase()}`}>{newData.status}</span>
                    </>
                ),
            },
            {
                label: 'Distribution',
                key: 'distribution',
                value: (
                    <div>
                        <div>
                            <span>Type: {newData?.distribution?.type}</span>&nbsp;&nbsp;&nbsp;
                            <span>Duration: {newData?.distribution?.duration / 3600}h</span>&nbsp;&nbsp;&nbsp;
                        </div>
                        <div>
                            {dayjs(newData?.distribution?.from * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')}
                            &nbsp;-&nbsp;
                            {dayjs(newData?.distribution?.till * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    </div>
                ),
            },
            {
                label: 'Audience',
                key: 'audiences',
                value: newData?.audiences?.map((audience, index) => (
                    <Audience key={index} audience={audience}>
                        Audience {(index + 1)}<br />
                    </Audience>
                )),
            },
            {
                label: 'Advanced flags',
                key: 'advancedFlags',
                value: (
                    <FormGroup row>
                        <FormControlLabel control={<Checkbox checked={newData?.fickle} />} label="Check Audience Every Time (fickle)" />
                        <FormControlLabel control={<Checkbox checked={newData?.repeatable} />} label="Allow repeat after finish (repeatable)" />
                    </FormGroup>
                ),
                changed: newData?.fickle !== oldData?.fickle || newData?.repeatable !== oldData?.repeatable,
            },
        ];

        if (adminAction === 'EVENT_UPDATE' || adminAction === 'EVENT_UPDATE_BY_TRANSFER' || adminAction === 'STATUS_CHANGING') {
            const changedKeys = getChangedRows(newData, oldData);
            return allRows.filter(row => changedKeys.includes(row.key) || row.changed);
        }

        return allRows;
    };

    console.log(item.adminAction)

    const infoBtn = item.info != null && Object.keys(item.info).length > 0 &&
        <Button variant={"outlined"} onClick={() => {
            setSelectedRow({
                oldData: item.oldData,
                newData: item.newData,
                info: item.info,
            });
            setOpenDiff(true);
            setOpenInfo(true);
        }}>Show Info</Button>

    const diffBtn = <Button
        size={"small"}
        variant={"outlined"}
        onClick={() => {
            setSelectedRow({
                oldData: item.oldData,
                newData: item.newData,
                info: item.info,
            });
            setOpenDiff(true);
        }}
    >
        json diff
    </Button>

    const getHistoryClass = (item) => {
        if(item.adminAction == 'BUNDLE_UPDATE' && item.info.hasOwnProperty('buildLink')) {
            return 'teamcity'
        }
        return item.userName.toLowerCase()
    }

    const getOwnerName = (item) => {
        if(item.adminAction == 'BUNDLE_UPDATE' && item.info.hasOwnProperty('buildLink')) {
            return <Link target={"_blank"} href={item.info.buildLink}>TeamCity </Link>
        }
        return item.userName
    }

    const hasBundleDiff = getBundleDiff(item.oldData, item.newData).length==0;
    const hasDataDiff = getTableRows(item).length==0

    return (
        <>
            <div className={"history-item-group " + (showDetails?"expanded":"")}>
                <div className={"history-item " + (showDetails?"expanded":"")}>
                    {0&&item.adminAction=='EVENT_UPDATE_BY_TRANSFER' &&
                    (hasBundleDiff||hasDataDiff) ?
                        <div class={"grey"}>no campaign or bundle changes</div>
                        :<>
                        <div
                            className={"history-item__date"}>{dayjs(item.creationDateSeconds * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')}</div>

                        {['EVENT_TRANSFER'].includes(item.adminAction)&&<div><SyncAltIcon fontSize={"small"} style={{padding: 0, margin:0}} /></div>}

                        <div className={"history-owner "+ getHistoryClass(item)}>{getOwnerName(item)}</div>

                        {/*<div className={item.adminAction.toLowerCase()}>{getTitle(item)}</div>*/}
                        <div>{getInfo(item)}</div>


                        {/*{['EVENT_CREATION','EVENT_UPDATE'].includes(item.adminAction)&&<div>{item.eventType.toLowerCase()}</div>}*/}

                        {false&&<div>{item.eventId}</div>}

                        {!['EVENT_TRANSFER'].includes(item.adminAction)&&<div className={"history-details"}>
                            {![''].includes(item.adminAction) ? <Button
                                size={"small"}
                                variant={"outlined"}
                                onClick={() => setShowDetails(!showDetails)}
                            >{!showDetails?<span>Details</span>:<span>Close</span>}</Button> : diffBtn}
                        </div>}

                        {!['EVENT_TRANSFER'].includes(item.adminAction)&&<div className={"history-details"}>
                            {infoBtn}
                        </div>}
                    </>}

                </div>

                {showDetails &&<div className={"history-item"}>
                    { ((['STATUS_CHANGING','EVENT_CREATION', 'EVENT_UPDATE', 'EVENT_UPDATE_BY_TRANSFER',
                        'EVENT_CREATION_BY_TRANSFER',
                        'EVENT_CREATION_BY_TEMPLATE'].includes(item.adminAction)
                        && item.eventType=='CAMPAIGN')
                        || item.adminAction == 'EVENT_TRANSFER'
                        )
                        && (<>
                            {getTableRows(item).length>0?<Table>
                                {getTableRows(item).map((row, index) => {
                                    return <TableRow key={index}>
                                        <TableCell style={{width: "134px"}}><b>{row.label}:</b></TableCell>
                                        <TableCell>{row.value}</TableCell>
                                    </TableRow>

                                })}

                            </Table>:<span className={"grey"}>no changes</span>}

                        </>
                    )}

                    {(['EVENT_UPDATE','EVENT_UPDATE_BY_TRANSFER','BUNDLE_UPDATE'].includes(item.adminAction)
                    )&&item.eventType=='BUNDLE'&&<>
                        <LogBundleDetails bundle={item.newData.id} generations={item.newData.generations} />
                    </>}

                    {!(['STATUS_CHANGING','EVENT_TRANSFER', 'EVENT_CREATION',
                            'EVENT_CREATION_BY_TRANSFER','EVENT_CREATION_BY_TEMPLATE'].includes(item.adminAction)
                    )||item.eventType=='BUNDLE'&&<>
                        <LogBundleDetails bundle={item.newData.id} generations={item.newData.generations} />
                    </>}


                    <div style={{ float: 'right' }}>
                        <div >
                            {diffBtn}
                        </div>
                    </div>
                </div>}


                {showDetails&&(item.info.hasOwnProperty('currentBundle')&&item.info?.currentBundle?.generations!=null)&&<div className={"history-item"}>
                    <LogBundleDetails bundle={item.info?.currentBundle?.id} generations={item.info?.currentBundle?.generations} />
                </div>}



            </div>
            {item.hasOwnProperty('related')&&item.related.length>0&&<div className={"history-item__related"}>
                {item.related.map(_item => (
                    <LogItem item={_item} setSelectedRow={setSelectedRow} setOpenDiff={setOpenDiff} setOpenInfo={setOpenInfo} />
                ))}
            </div>}
        </>
    )
})

export default LogItem