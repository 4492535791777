import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    Checkbox, Chip, ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl, InputLabel,
    Link, List, ListItem, ListItemText,
    MenuItem,
    Select,
    Tooltip, Typography
} from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';

import CampaignDetailsDialog from "../../Dialogs/CampaignDetailsDialog";
import HistoryDialog from "../../Dialogs/HistoryDialog";
import CampaignJsonDialog from "../../Dialogs/CampaignJsonDialog";
import Audience from "../Audience";
import {useSetStatusMutation} from "../../../core/reducers/campaign";
import CampaignBundleDialog from "../../Dialogs/CampaignBundleDialog";
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/ContentCopy';
import {LoadingButton} from "@mui/lab";
import {CampaignItemContext} from "../../../core/context/context";
import dayjs from "dayjs";

import clipboardCopy from 'clipboard-copy';
import {SnackBarContext} from "../../../core/context/snackBarProvider";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {isProdAdmin} from "../../Utils/CommonUtils";
import CampaignBundleRollbackDialog from "../../Dialogs/CampaignBundleRollbackDialog";
import FmdBadIcon from '@mui/icons-material/FmdBad';
import {useSetTemplateStatusMutation} from "../../../core/reducers/templates";
import { styled } from '@mui/material/styles';
import StatusSelector from "../StatusSelector";


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        boxShadow: '3px 1px 6px 4px rgba(0,0,0,0.2)',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '0px solid #dadde9',
    },
}));

const CampaignItemTimeleft = ({item, showDetails, showRainbow}) => {
    const {campaigns, types, type, items, bundles, onSelectItem, selectedList, env, createDisabled, isTemplate} = useContext(CampaignItemContext)

    const campaignType = useMemo(() => { return campaigns.types.filter(_ct => _ct.readableType == item.type)[0] }, [item.type])


    const [status, setStatus] = useState('')
    const [prevStatus, setPrevStatus] = useState('')

    const [timeLeftTill, setTimeLeftTill] = useState()
    const [timeLeftFrom, setTimeLeftFrom] = useState()
    const [timeLeftFromMinus, setTimeLeftFromMinus] = useState()
    const [timeLeftTillPlus, setTimeLeftTillPlus] = useState();

    const [bundleHistoryDialog, setBundleHistoryDialog] = useState({
        isOpen:false,
        generation: '',
        bundle: item.bundleId
    })

    const sourceMode = useSelector((state) => state.data.SourceMode);
    const appMode = sourceMode.mode;

    const navigate = useNavigate();



    const userSession = useSelector((state) => state.data.UserSession);

    const timeClassName = useMemo(() => {
        if (!showRainbow) return '';
        if (campaignType == null) return '';

        let current_time = new Date().getTime() / 1000;

        if (current_time < (item.distribution.from - campaignType.announceDuration)) return 'aqua';
        if (
            current_time >= (item.distribution.from - campaignType.announceDuration) &&
            current_time < item.distribution.from
        ) return 'lightgreen';
        if (
            current_time >= item.distribution.from &&
            current_time < item.distribution.till
        ) return 'green';
        if (
            current_time >= item.distribution.till &&
            current_time < (item.distribution.till + campaignType.extraDuration)
        ) return 'yellow';
        if (current_time >= (item.distribution.till + campaignType.extraDuration)) return 'red';

        return '';
    }, [showRainbow, campaignType, item.distribution]);


    const formatdate = (timestamp) => {
        let d = dayjs(timestamp * 1000).utcOffset(180).format('YYYY-MM-DD HH:mm:ss')
        return d;
    }

    const getTimeLeft = (targetDate) => {
        const now = new Date();
        const target = new Date(targetDate * 1000);


        const timeDifference = target - now;


        if (timeDifference <= 0) {
            return "0d 00:00:00";
        }

        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return `${days}d ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    useEffect(() => {
        if (campaignType != null) {
            const updateTimeLeft = () => {
                setTimeLeftTill(getTimeLeft(item.distribution.till));
                setTimeLeftTillPlus(getTimeLeft(item.distribution.till + campaignType.extraDuration));
                setTimeLeftFrom(getTimeLeft(item.distribution.from));
                setTimeLeftFromMinus(getTimeLeft(item.distribution.from - campaignType.announceDuration));
            };

            updateTimeLeft(); // Обновить сразу при монтировании
            const timeleftInterval = setInterval(updateTimeLeft, 1000);
            return () => {
                clearInterval(timeleftInterval);
            };
        }
    }, [item.distribution.till, item.distribution.from]);

    const isPermanent = () => {
        return item.distribution.till == '32503680000'
    }


    const canChangeStatus = () => {
        if(env == "production" && prevStatus=="DEACTIVATED") return false;
        if(env == "production" && prevStatus=="COMPLETED" && status != "DEACTIVATED") return false;

        return true;
    }



    return (
        <>

                <div className={"campaign-timewrap "+ timeClassName}>
                    <div className="campaign-timeblock">
                        <div className="campaign-item__starttime">
                            <Tooltip
                                title="Main Phase From">
                                <span>{formatdate(item.distribution.from)}</span>
                            </Tooltip>
                        </div>
                        <div className="campaign-item__countdown-timer">
                            <Tooltip
                                title="Timeleft before Main Phase">
                                <span>{timeLeftFrom}</span>
                            </Tooltip>
                        </div>
                        {showDetails &&
                            <>
                                <>
                                    <div className="campaign-item__starttime">
                                        <Tooltip
                                            title="Announce From">
                                            <span>{formatdate(item.distribution.from - campaignType?.announceDuration)}</span>
                                        </Tooltip>
                                    </div>
                                    <div className="campaign-item__countdown-timer">
                                        <Tooltip
                                            title="Timeleft before Announce">
                                            <span>{timeLeftFromMinus}</span>
                                        </Tooltip>
                                    </div>

                                </>

                            </>
                        }

                    </div>

                    <div className="campaign-timeblock">

                        <div className="campaign-item__endtime">
                            <Tooltip
                                title="Main Phase Till">
                                <span>{formatdate(item.distribution.till)}</span>
                            </Tooltip>
                        </div>
                        <div className="campaign-item__countdown-timer">
                            <Tooltip
                                title="Timeleft before Main Phase">
                                <span>{!isPermanent()?timeLeftTill:<>-</>}</span>
                            </Tooltip>
                        </div>
                        {showDetails&&!isPermanent() &&
                            <>
                                <div className="campaign-item__endtime">
                                    <Tooltip
                                        title="Extra Time Till">
                                        <span>{formatdate(item.distribution.till + campaignType?.extraDuration)}</span>
                                    </Tooltip>
                                </div>
                                <div className="campaign-item__countdown-timer">
                                    <Tooltip
                                        title="Timeleft left before Extra Time">
                                        <span>{timeLeftTillPlus}</span>
                                    </Tooltip>
                                </div>
                            </>
                        }

                    </div>
                    {showDetails&&<div style={{"clear":"both"}}>Distribution type: <b>{item.distribution.type}</b></div>}

                </div>

        </>
    )

}

export default React.memo(CampaignItemTimeleft);
