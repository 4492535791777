import dayjs from "dayjs";
import {Button, Checkbox, FormControlLabel, FormGroup, Link, Table, TableCell, TableRow} from "@mui/material";
import {memo, useState} from "react";
import Audience from "../Publisher/Audience";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

const LogBundleDetails = memo(function({generations, bundle}) {


    return (
        <Table>
            <TableRow>
                <TableCell style={{"width":"134px"}}><b>Bundle ID:</b></TableCell>
                <TableCell>{bundle}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{"width":"134px"}}><b>Bundles:</b></TableCell>
                <TableCell>{Object.keys(generations).map((key) => {
                    return <div style={{marginBottom:"5px"}}>
                        <span className={"tag"}>g{key}/v{generations[key].bundleVersion}</span>&nbsp;
                        for {generations[key].clientVersion.version}&nbsp;
                        {generations[key].data?.buildLink!=null&&<Link className={"button-outlined"} href={generations[key].data?.buildLink} target={"_blank"}>Show in Teamcity</Link>}
                        <div className={"history-details__platforms"}>
                            {generations[key].data!==null&&Object.keys(generations[key].data?.platformToCatalogFile).join(', ')}
                        </div>

                    </div>
                })}</TableCell>
            </TableRow>
        </Table>
    )
})

export default LogBundleDetails