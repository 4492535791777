import React from "react";
import {Chip} from "@mui/material";

export default function AudienceOffer({audience}) {

    function convertSecondsToISO8601Duration(seconds) {
        const days = Math.floor(seconds / 86400);
        const hours = Math.floor((seconds % 86400) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        let duration = 'P';
        if (days > 0) duration += `${days}D`;
        if (hours > 0 || minutes > 0 || secs > 0) {
            duration += 'T';
            if (hours > 0) duration += `${hours}H`;
            if (minutes > 0) duration += `${minutes}M`;
            if (secs > 0) duration += `${secs}S`;
        }

        return duration;
    }

    const operator = {
        'GREATER_THAN':'>',
        'LESS_THAN':'<',
    }

    const getOffestLabel = (str) => {
        if (typeof str !== 'string') {
            return str;
        }

        const match = str.match(/\d+/);
        if(!match) return str;

        if(Number(match[0])%24==0) {
            return Number(match[0])/24 + ' days ago'
        } else {
            return Number(match[0]) + ' hours ago'

        }
    }

    const getLabel = () => (
        <>
            <em>{audience.parameter} </em>

            ({audience.hasOwnProperty('offerId') && <>{audience.offerId}</>}
            {audience.hasOwnProperty('offerIds') && <>{audience.offerIds.join(', ')}</>}
            {audience.hasOwnProperty('types') && <>{audience.types.join(', ')}</>}
            {audience.hasOwnProperty('tags') && <>{audience.tags.join(', ')}</>}
            )
            {audience.hasOwnProperty('operator') && <>&nbsp;{operator[audience.operator]}</>}
            {audience.hasOwnProperty('offset') && <>&nbsp;{getOffestLabel(audience.offset )}&nbsp;</>}
        </>
    )

    return (
        <Chip label={getLabel()}/>
    )

}